<script>
import PageHeader from "@/components/page-header";
import { ApiClientSelectedPlatform } from "@/common/http-common"
import { BredcrumpManagerDecode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { networkRequesListResponseLogic } from "@/common/http-common-response-list"
export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$t('tags.tag'),
      items: [],
      pagination: {
        search: null,
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10,
        nextPages: [],
        backPages: []
      }

    };
  },
  watch: {
    $route(to, from) {
      if (to.name == "tags" && from.name == "platform/tags")
        window.location.reload(true);
    }
  },
  methods: {
    validationSearchField(search) {
      console.log(search)
      const self = this;
      var rs = JSON.parse(atob(this.$route.query.data))
      var size = 10
      var page = 1
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/domains/item/tag/list?page=" + page + "&size=" + size + "&search=" + search).then(function (response) {
        networkRequesListResponseLogic(self, response, page, size, "tags.tag")
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )

    },
    getData: function (page, size, search) {
      const self = this;
      console.log(search)
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/domains/item/tag/list?page=" + page + "&size=" + size).then(function (response) {
        networkRequesListResponseLogic(self, response, page, size, "tags.tag")
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
    configureDataNextModule: function (id) {
      if (this.$route.query.data) {
        var rs = JSON.parse(atob(this.$route.query.data))
        rs.itemId = id
        return btoa(JSON.stringify(rs))
      }
    },
    configureLinkNextModule: function (url) {
      if (this.$route.path.includes("platform/detail")) {
        return "/platform/detail/" + url
      } else {
        return url
      }
    }
  },
  mounted() {
    if (this.$route.query.data) {
      var data = BredcrumpManagerDecode(this)
      if (data) {
        this.pagination.page = parseInt(data.page)
        this.pagination.pageSize = parseInt(data.size)
        this.getData(this.pagination.page, this.pagination.pageSize)
      } else {
        this.getData(this.pagination.page, this.pagination.pageSize)
      }
    } else {
      this.getData(this.pagination.page, this.pagination.pageSize)
    }
  }
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12">
      <PageHeader :title="title" />
    </div>
    <div class="col-xl-12">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-xl-3">
                  <h4 class="card-title">{{ this.$t('tags.tag') }}</h4>
                  <p class="card-title-desc">
                    <router-link
                      :to="{ path: configureLinkNextModule('tags/create'), query: { data: configureDataNextModule(null) } }">{{
                        $t("tags.create") }}</router-link>
                  </p>
                </div>
                <div class="col-xl-9">
                  <b-form-input id="platform" v-model="pagination.search" @input="validationSearchField"
                    :placeholder="this.$t('form.search.fields.search')"></b-form-input>
                </div>
              </div>
              <div class="row" v-if="items.length != 0">
                <div class="col-xl-12">
                  <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> {{ this.$t("tags.list.tag") }}
                      </th>
                      <th> {{ this.$t("tags.list.description") }}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.id }}</td>
                      <td>{{ item.title }}</td>
                      <td>{{ item.description }}</td>
                      <td><router-link
                          :to="{ path: configureLinkNextModule('tags/update'), query: { data: configureDataNextModule(item.id) } }">{{
                            $t("tags.update") }}</router-link></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-4" v-if="pagination.totalPages != 1 && items.length != 0">
                <div class="col-sm-6">
                  <div>
                    <p class="mb-sm-0 mt-2">
                      {{ this.$t("pagination.page") }}
                      <span class="font-weight-bold">{{ pagination.page }}</span> {{ this.$t("pagination.from") }}
                      <span class="font-weight-bold">{{ pagination.totalPages }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="float-sm-right">
                    <ul class="pagination pagination-rounded mb-sm-0">
                      <li class="page-item" v-bind:class="(pagination.page == 1) ? 'disabled' : ''">
                        <a href="#" class="page-link"
                          @click="getData(parseInt(pagination.page) - 1, pagination.pageSize)">
                          <i class="mdi mdi-chevron-left"></i>
                        </a>
                      </li>
                      <li class="page-item" v-for="item in pagination.backPages" :key="item">
                        <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                      </li>
                      <li class="page-item active">
                        <a href="#" class="page-link">{{ pagination.page }}</a>
                      </li>
                      <li class="page-item" v-for="item in pagination.nextPages" :key="item">
                        <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                      </li>
                      <li class="page-item" v-bind:class="(pagination.page == pagination.totalPages) ? 'disabled' : ''">
                        <a href="#" class="page-link" @click="getData(parseIn(pagination.page) + 1, pagination.pageSize)">
                          <i class="mdi mdi-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-xl-12">
                   <p style="color:red;margin-bottom: 0px!important;"> {{ this.$t('form.search.validation.search') }}</p>
                  </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>